.logo:hover {
  cursor: pointer;
}

#header ul {
  display: inline-flex;
}

#header ul li {
  list-style-type: none;
}

.main {
  max-width: 95%;
  margin: auto;
}

.carousel-q-container {
  width: 100%;
}

.multi-line-text2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 2.5rem;
}

.multi-line-text2-adm {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  height: 0.75rem;
}

.multi-line-tes-adm {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 2.5rem;
}

.multi-line-text {
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  height: 2.75rem;
}

.multi-line-art {
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  height: 30px;
}

.main-admin {
  min-width: 1200px;
  margin: auto;
}

.main-adminXL {
  max-width: 1440px;
  margin: auto;
}

input:hover {
  cursor: pointer;
}

.sun-editor .se-wrapper {
  z-index: 0 !important;
}

.sun-editor .se-toolbar {
  z-index: unset !important;
}

.box-preview {
  height: 375px;
  background-color: #ffff;
  border: 1px solid lightgrey;
  overflow: auto;
}

.box-preview span {
  line-height: 24px;
  letter-spacing: 0.02em;
}

.shape-style-circle {
  margin: auto;
  width: 4rem;
  border-radius: 4rem;
}

.shape-style-rectangular {
  width: 100%;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-in-top {
  -webkit-animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.slide-in-left {
  -webkit-animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.box-image-zoom {
  overflow: hidden;
}
.box-image-zoom img {
  transition: transform 0.5s ease;
}
.box-image-zoom:hover img {
  transform: scale(1.5);
}

/* Custom Scrollbar */
.custom-scrollbar {
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
.custom-scrollbar.x {
  overflow-x: auto;
  border-radius: 0.75rem;
}
.custom-scrollbar.y {
  overflow-y: auto;
  border-radius: 0.75rem;
}
.custom-scrollbar.x::-webkit-scrollbar {
  height: 10px !important;
}
.custom-scrollbar.y::-webkit-scrollbar {
  width: 10px !important;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 1rem;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #dadce0;
  border-radius: 1rem;
}

[class^="fi-"]:before,
[class*=" fi-"]:before,
[class^="fi-"]:after,
[class*=" fi-"]:after {
  font-size: 18px;
}

.selected {
  width: 100%;
  animation: slide-in 1s forwards;
  -webkit-animation: slide-in 1s forwards;
}

.dismiss {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(25%);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(25%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(50%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(50%);
  }
}

.container:hover > .btnHoverEdit {
  display: block;
}

.sect.root-stack {
  position: relative;
  width: 100%;
  display: flex;
  z-index: 1;
}

.row.root-stack {
  height: 100%;
}

.sect.root-stack .row.root-stack::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  /* background: linear-gradient(to bottom,
    transparent 0%,
    rgba(0,0,0,0.25) 95%);  */
  z-index: -1;
}
#video-bg {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  z-index: -2;
}
.sect.root-stack .col.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

/* .sect.footer  {
  position: relative;
} */

.col.img-stack {
  position: relative;
}
.el.img-stack {
  position: absolute;
  top: -2rem;
  left: -2rem;
  object-fit: cover;
  object-position: center;
  border: 8px solid #ffff;
  width: 100%;
  height: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.sect.simpleScroll {
  position: relative;
  overflow: hidden;
}

.simpleScrollItem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  overflow-x: auto;
  align-items: center;
  scroll-snap-type: x mandatory;
}
.simple-scroll {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: cover;
  object-position: center;
}

.running-text {
  display: inline-block;
  padding-left: 100%;
  animation: running-text 60s linear infinite, step-start infinite;
}

a {
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 100%;
}

@keyframes running-text {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes blinking {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@media screen and (max-width: 900px) {
  .simpleScrollItem {
    position: unset;
  }
  .el.img-stack {
    top: -2rem;
    left: 5%;
    right: 5%;
    width: 90%;
  }
}
